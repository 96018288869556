import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Upload_icon from '../images/uploadcv-icon.svg';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import jar from '../helpers/cookies';
import { URL } from '../config/URL';
import Loading from '../components/loader/Loading';
import spinner from '../images/Spinner.svg';
import { useUser } from '../context/UserContext';
// import Select from 'react-select';
// import { Tooltip } from 'react-tooltip';

const toastConfig = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};

const Onboarding = () => {
  const { handleGetResumeData } = useUser();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileMessage, setFileMessage] = useState('');
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [_id, setUploadedResumeId] = useState();
  const [key, setUploadedResumeKey] = useState();

  const bearerToken = jar.get('token');
  const apiUrlData = `${URL}/resume`;
  const apiUrlResume = `${URL}/resume/upload`;

  // File Upload

  const handleFileValidation = (uploadedFile) => {
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    if (uploadedFile && allowedTypes.includes(uploadedFile.type)) {
      setFile(uploadedFile);
      setFileErrorMessage('');
      setFileMessage(''); // Clear any previous error message
    } else {
      setFile(null);
      setFileMessage('');
      setFileErrorMessage(
        'Invalid file type. Only PDF, DOC, and DOCX files are allowed.'
      );
    }
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    handleFileValidation(uploadedFile);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    handleFileValidation(droppedFile);
  };

  // Upload Resume File

  function uploadFile(file) {
    let data = new FormData();
    data.append('resume', file);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiUrlResume,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      },
      data: data
    };

    axios
      .request(config)
      .then((response) => {
        console.log('Resume upload:', JSON.stringify(response.data));
        // console.log('Message: ', response.data.msg);
        setFileMessage(response.data.msg);
        setUploadedResumeId(response.data.data._id);
        setUploadedResumeKey(response.data.data.key);
        toast.success(response.data.msg, toastConfig);
      })
      .catch((error) => {
        // console.log('Error msg:', error.response.data.msg);
        setFileMessage(error.response.data.msg);
        toast.warn(error.response.data.msg, toastConfig);
      })
      .finally(() => {
        setUploadingFile(false);
      });
  }
  useEffect(() => {
    if (file) {
      setUploadingFile(true);
      uploadFile(file);
    }
  }, [file]);

  useEffect(() => {
    if (file) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [file]);

  //// Form Validation
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = async (data) => {
    setIsLoading(true);

    data.current_salary.value = parseFloat(data.current_salary.value);
    data.expected_salary.value = parseFloat(data.expected_salary.value);
    try {
      let config = {
        method: 'post',
        url: `${apiUrlData}/${_id}`,
        headers: {
          Authorization: `Bearer ${bearerToken}`
        },
        data: data
      };

      const response = await axios.request(config);
      console.log('Submit Data:', JSON.stringify(response));
      toast.success('Successfully Submitted', toastConfig);

      setUploadingFile(true);
      toast.success('Fetching Rsume Information...', toastConfig);
      const ParseResumeData = await handleGetResumeData(key);
      const resumeData = {
        ...ParseResumeData,
        _id,
        key
      };

      navigate('/edit-resume', {
        state: { resumeData }
      });
      setUploadingFile(false);
    } catch (error) {
      console.log('Response Error', error);
      if (error.response && error.response.status === 422) {
        if (error.response.data && Array.isArray(error.response.data.data)) {
          toast.error('Error (422 Unprocessable Entity)', toastConfig);
        } else {
          toast.error('Error (422 Unprocessable Entity)', toastConfig);
        }
      } else {
        toast.error('Unexpected error occurred', toastConfig);
      }
      setUploadingFile(false);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setUploadingFile(false);
    }
  };

  const [formData, setFormData] = useState({
    gender: '',
    ethnicity: '',
    criminal_status: '',
    disability_status: '',
    require_visa_sponsorship: '',
    has_driver_license: '',
    veterans_status: '',
    preferred_way_of_working: '',
    have_own_vehicle: '',
    // tell_about_self: '',
    'current_salary.value': '',
    'current_salary.currency': '',
    'expected_salary.value': '',
    'expected_salary.currency': '',
    has_security_clearance: '',
    // skills: [],
    file: null
  });
  // console.log('Progress', formData);
  const handlePercentage = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const calculatePercentage = () => {
    const totalFields = Object.keys(formData).length;

    const fieldsToExclude = ['file'];

    const filledFields = Object.keys(formData).filter(
      (key) => !fieldsToExclude.includes(key) && formData[key] !== ''
    ).length;
    const progress =
      (filledFields / (totalFields - fieldsToExclude.length)) * 100;
    return isNaN(progress) ? 0 : progress.toFixed(0);
  };

  // const [personalityOptionLimit, setPersonalityOptionLimit] = useState([]);

  // const personalityOptions = [
  //   {
  //     label: 'Confident',
  //     value: 'Confident'
  //   },
  //   {
  //     label: 'Adaptable',
  //     value: 'Adaptable'
  //   },
  //   {
  //     label: 'Resilient',
  //     value: 'Resilient'
  //   },
  //   {
  //     label: 'Collaborative',
  //     value: 'Collaborative'
  //   },
  //   {
  //     label: 'Innovative',
  //     value: 'Innovative'
  //   },
  //   {
  //     label: 'Analytical',
  //     value: 'Analytical'
  //   },
  //   {
  //     label: 'Empathetic',
  //     value: 'Empathetic'
  //   },
  //   {
  //     label: 'Organized',
  //     value: 'Organized'
  //   },
  //   {
  //     label: 'Proactive',
  //     value: 'Proactive'
  //   },
  //   {
  //     label: 'Ethical',
  //     value: 'Ethical'
  //   }
  // ];

  // console.log('Personality:', personalityOptionLimit);

  return (
    <>
      {isLoading && <Loading />}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-md-3  userinfo-side d-none d-sm-none d-md-block px-1">
            <div className="overlay"></div>
            <div className="progress-container">
              <div className="barcontainer">
                <div
                  className="bar"
                  style={{ height: `${calculatePercentage()}%` }}
                />
                <div className="percentage text-center align-middle">
                  <div className="text-center">
                    <span className="mx-auto">{`${calculatePercentage()}%`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-10 col-md-9 col-12 pe-0">
            <div className="content-box">
              <form id="onboarding_form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row p-4">
                  <div className="col-lg-4 col-md-6 col-12 fetch-filter my-2">
                    <Controller
                      name="gender"
                      control={control}
                      rules={{
                        required: '*'
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <p className="userinfo-select mb-2">
                            Gender
                            {errors.gender && (
                              <span className="text-danger fw-bold">
                                {errors.gender.message}
                              </span>
                            )}
                          </p>
                          <div className="mb-3 onboard-select">
                            <select
                              className="py-3 px-2 w-100"
                              id="inputGroupSelect04"
                              aria-label="Example select with button addon"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handlePercentage(e);
                              }}
                            >
                              <option value="">Select Option</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="null">Prefer not to say</option>
                            </select>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 fetch-filter my-2">
                    <Controller
                      name="ethnicity"
                      control={control}
                      rules={{
                        required: '*'
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <p className="userinfo-select mb-2">
                            Race and Ethnicity
                            {errors.ethnicity && (
                              <span className="text-danger fw-bold">
                                {errors.ethnicity.message}
                              </span>
                            )}
                          </p>
                          <div className="mb-3 onboard-select">
                            <select
                              className="py-3 px-2 w-100"
                              id="inputGroupSelect04"
                              aria-label="Example select with button addon"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handlePercentage(e);
                              }}
                            >
                              <option value="">Select Option</option>
                              <option value="hispanic_or_latino">
                                Hispanic or Latino
                              </option>
                              <option value="american_indian_or_alaskan_native">
                                American Indian or Alaska Native
                              </option>
                              <option value="asian">Asian</option>
                              <option value="black_or_african_american">
                                Black or African American
                              </option>
                              <option value="native_hawaiian_or_other_pacific_islander">
                                Native Hawaiian or Other Pacific Islander
                              </option>
                              <option value="white">White</option>
                              <option value="two_or_more_races">
                                Two or more races
                              </option>
                              <option value="decline_to_self_identify">
                                Decline to self identify
                              </option>
                            </select>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 fetch-filter my-2">
                    <Controller
                      name="criminal_status"
                      control={control}
                      rules={{
                        required: '*'
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <p className="userinfo-select mb-2">
                            Criminal Status
                            {errors.criminal_status && (
                              <span className="text-danger fw-bold">
                                {errors.criminal_status.message}
                              </span>
                            )}
                          </p>
                          <div className="mb-3 onboard-select">
                            <select
                              className="py-3 px-2 w-100"
                              id="inputGroupSelect04"
                              aria-label="Example select with button addon"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handlePercentage(e);
                              }}
                            >
                              <option value="">Select Option</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 fetch-filter my-2">
                    <Controller
                      name="disability_status"
                      control={control}
                      rules={{
                        required: '*'
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <p className="userinfo-select mb-2">
                            Disability Status
                            {errors.disability_status && (
                              <span className="text-danger fw-bold">
                                {errors.disability_status.message}
                              </span>
                            )}
                          </p>
                          <div className="mb-3 onboard-select">
                            <select
                              className="py-3 px-2 w-100"
                              id="inputGroupSelect04"
                              aria-label="Example select with button addon"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handlePercentage(e);
                              }}
                            >
                              <option value="">Select Option</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 fetch-filter my-2">
                    <Controller
                      name="require_visa_sponsorship"
                      control={control}
                      rules={{
                        required: '*'
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <p className="userinfo-select mb-2">
                            Require Sponsorship visa, in future
                            <span> (e.g H-1B visa status)</span>
                            {errors.require_visa_sponsorship && (
                              <span className="text-danger fw-bold">
                                {errors.require_visa_sponsorship.message}
                              </span>
                            )}
                          </p>
                          <div className="mb-3 onboard-select">
                            <select
                              className="py-3 px-2 w-100"
                              id="inputGroupSelect04"
                              aria-label="Example select with button addon"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handlePercentage(e);
                              }}
                            >
                              <option value="">Select Option</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 fetch-filter my-2">
                    <Controller
                      name="has_driver_license"
                      control={control}
                      rules={{
                        required: '*'
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <p className="userinfo-select mb-2">
                            Do you have a driver`s license
                            {errors.has_driver_license && (
                              <span className="text-danger fw-bold">
                                {errors.has_driver_license.message}
                              </span>
                            )}
                          </p>
                          <div className="mb-3 onboard-select">
                            <select
                              className="py-3 px-2 w-100"
                              id="inputGroupSelect04"
                              aria-label="Example select with button addon"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handlePercentage(e);
                              }}
                            >
                              <option value="">Select Option</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 fetch-filter my-2">
                    <Controller
                      name="veterans_status"
                      control={control}
                      rules={{
                        required: '*'
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <p className="userinfo-select mb-2">
                            Veterans Status
                            {errors.veterans_status && (
                              <span className="text-danger fw-bold">
                                {errors.veterans_status.message}
                              </span>
                            )}
                          </p>
                          <div className="mb-3 onboard-select">
                            <select
                              className="py-3 px-2 w-100"
                              id="inputGroupSelect04"
                              aria-label="Example select with button addon"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handlePercentage(e);
                              }}
                            >
                              <option value="">Select Option</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                              <option value="none">
                                I do not wish to answer
                              </option>
                            </select>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 fetch-filter my-2">
                    <Controller
                      name="preferred_way_of_working"
                      control={control}
                      rules={{
                        required: '*'
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <p className="userinfo-select mb-2">
                            Preferred way of working
                            {errors.preferred_way_of_working && (
                              <span className="text-danger fw-bold">
                                {errors.preferred_way_of_working.message}
                              </span>
                            )}
                          </p>
                          <div className="mb-3 onboard-select">
                            <select
                              className="py-3 px-2 w-100"
                              id="inputGroupSelect04"
                              aria-label="Example select with button addon"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handlePercentage(e);
                              }}
                            >
                              <option value="">Select Option</option>
                              <option value="on-site">On-site</option>
                              <option value="remote">Remote</option>
                              <option value="hybrid">Hybrid</option>
                            </select>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 fetch-filter my-2">
                    <Controller
                      name="have_own_vehicle"
                      control={control}
                      rules={{
                        required: '*'
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <p className="userinfo-select mb-2">
                            Do you have your own vehicle?
                            {errors.have_own_vehicle && (
                              <span className="text-danger fw-bold">
                                {errors.have_own_vehicle.message}
                              </span>
                            )}
                          </p>
                          <div className="mb-3 onboard-select">
                            <select
                              className="py-3 px-2 w-100"
                              id="inputGroupSelect04"
                              aria-label="Example select with button addon"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handlePercentage(e);
                              }}
                            >
                              <option value="">Select Option</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </>
                      )}
                    />
                  </div>
                </div>

                <div className="row border-grey-top">
                  <div className="col-lg-6 col-md-12 col-12 p-5 otherInfo-div">
                    <p className="userinfo-other">Some other information</p>
                    <div className="d-flex gap-1 my-2">
                      <Controller
                        name="current_salary.value"
                        control={control}
                        rules={{
                          required: 'This field is required'
                        }}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            id="current_salary_value"
                            className={`col mx-auto userinfo-input ${
                              errors.current_salary?.value
                                ? 'border-danger'
                                : ''
                            }`}
                            type="number"
                            placeholder="Current Salary"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              handlePercentage(e);
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="current_salary.currency"
                        control={control}
                        rules={{
                          required: 'This field is required'
                        }}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <select
                              className={`col mx-auto userinfo-input ${
                                errors.current_salary?.currency
                                  ? 'border-danger'
                                  : ''
                              }`}
                              id="current_salary_currency"
                              aria-label="Example select with button addon"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handlePercentage(e);
                              }}
                            >
                              <option value="">Select Currency</option>
                              <option value="GBP">GBP</option>
                              <option value="USD">USD</option>
                              <option value="CAD">CAD</option>
                              <option value="NZD">NZD</option>
                              <option value="ZAR">ZAR</option>
                              <option value="AUD">AUD</option>
                              <option value="EUR">EUR</option>
                              <option value="BRL">BRL</option>
                            </select>
                            {/* {errors.current_salary?.currency && (
                              <span className="text-danger fw-bold">
                                {errors.current_salary.currency.message}
                              </span>
                            )} */}
                          </>
                        )}
                      />
                    </div>
                    <div className="d-flex gap-1 my-2">
                      <Controller
                        name="expected_salary.value"
                        control={control}
                        rules={{
                          required: 'This field is required'
                        }}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <input
                              id="expected_salary_value"
                              className={`col mx-auto userinfo-input ${
                                errors.expected_salary?.value
                                  ? 'border-danger'
                                  : ''
                              }`}
                              type="number"
                              placeholder="Expected Salary"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handlePercentage(e);
                              }}
                            />
                            {/* {errors.expected_salary?.value && (
                              <span className="text-danger fw-bold">
                                {errors.expected_salary.value.message}
                              </span>
                            )} */}
                          </>
                        )}
                      />

                      <Controller
                        name="expected_salary.currency"
                        control={control}
                        rules={{
                          required: 'This field is required'
                        }}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <select
                              className={`col mx-auto userinfo-input ${
                                errors.expected_salary?.currency
                                  ? 'border-danger'
                                  : ''
                              }`}
                              id="expected_salary_currency"
                              aria-label="Example select with button addon"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                handlePercentage(e);
                              }}
                            >
                              <option value="">Select Currency</option>
                              <option value="GBP">GBP</option>
                              <option value="USD">USD</option>
                              <option value="CAD">CAD</option>
                              <option value="NZD">NZD</option>
                              <option value="ZAR">ZAR</option>
                              <option value="AUD">AUD</option>
                              <option value="EUR">EUR</option>
                              <option value="BRL">BRL</option>
                            </select>
                            {/* {errors.expected_salary?.currency && (
                              <span className="text-danger fw-bold">
                                {errors.expected_salary.currency.message}
                              </span>
                            )} */}
                          </>
                        )}
                      />
                    </div>
                    <p>Do you have an active security clearance? (Optional)</p>
                    <div className="d-flex">
                      <Controller
                        name="has_security_clearance"
                        control={control}
                        rules={{
                          required: 'Please select an option'
                        }}
                        render={({ field }) => (
                          <>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={field.name}
                                id="clearance_yes"
                                value="true"
                                checked={field.value === 'true'}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  handlePercentage(e);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="clearance_yes"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check mx-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={field.name}
                                id="clearance_no"
                                value="false"
                                checked={field.value === 'false'}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  handlePercentage(e);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="clearance_no"
                              >
                                No / Not Applicable
                              </label>
                            </div>
                          </>
                        )}
                      />
                    </div>
                    {errors.has_security_clearance && (
                      <span className="text-danger fw-semibold">
                        {errors.has_security_clearance.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 py-5 px-4 border-grey-left">
                    <p className="text-center skill-box-h mb-2">
                      Let <strong>ResumeSnapshot</strong> do the heavy Lifting!
                    </p>
                    <p className="text-center skill-box-p mb-2 mx-auto">
                      Upload your CV/Resume and let our Advanced AI System
                      complete your profile!
                    </p>
                    <div className="row mt-4">
                      <div className="col-7 mx-auto">
                        <div className="text-center">
                          <label
                            htmlFor="upload_cv"
                            className="upload-cv"
                            onDrop={handleDrop}
                            onDragOver={(event) => event.preventDefault()}
                          >
                            <input
                              className="d-none"
                              id="upload_cv"
                              type="file"
                              onChange={handleFileUpload}
                            />
                            <img
                              className="mt-2"
                              height={60}
                              width={60}
                              src={Upload_icon}
                              alt="Upload Icon"
                            />{' '}
                            <br />
                            {file ? (
                              <>
                                <p className="mt-1">{file.name}</p>
                                {uploadingFile && (
                                  <img src={spinner} alt="Spinner" />
                                )}
                              </>
                            ) : (
                              <>
                                <span className="h">
                                  Upload Your CV/Resume{' '}
                                  <span className="text-danger fw-bold">*</span>
                                </span>
                                <br />
                                <p className="p m-0">
                                  or drag and drop your file into this box.
                                  Acceptable file types: DOC, DOCX, PDF
                                </p>
                                <span className="filename"></span>
                              </>
                            )}
                          </label>
                          <p className="text-danger">{fileErrorMessage}</p>
                          <p>{fileMessage}</p>
                        </div>
                      </div>
                      <div className="mt-4 pt-3 text-center">
                        <button
                          type="submit"
                          className="user-info-submit btn btn-primary"
                          disabled={isSubmitDisabled}
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Onboarding;
