import React from 'react';
import PropTypes from 'prop-types';

const EditResumeChecklist = ({
  isOpen,
  onClose,
  sections,
  scrollToSection,
  highlight,
  confirmations,
  handleConfirmation
}) => {
  if (!isOpen) return null;

  return (
    <div
      className="floating-menu"
      style={{
        position: 'absolute',
        left: '10px',
        bottom: '40px',
        backgroundColor: '#fff',
        color: '#000',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
        padding: '10px',
        zIndex: 1000,
        width: '220px'
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="fw-bold mb-3">My Checklist</h5>
        <button
          className="btn bg-transparent border-0 p-0 mb-3"
          onClick={onClose}
        >
          <svg
            width="25"
            height="25"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41 15.59 7Z"></path>
          </svg>
        </button>
      </div>
      {sections.map(({ id, name }) => (
        <div key={id} className="d-flex justify-content-between mb-1">
          <button
            id={`${id}-btn`}
            className={`bg-transparent border-0 fw-semibold ${
              highlight[id] ? '' : 'text-primary'
            }`}
            onClick={() => scrollToSection(id)}
          >
            {name}
          </button>
          <input
            className="form-check-input border-primary"
            type="checkbox"
            checked={confirmations[id]}
            onChange={() => handleConfirmation(id)}
          />
        </div>
      ))}
    </div>
  );
};

EditResumeChecklist.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  scrollToSection: PropTypes.func.isRequired,
  highlight: PropTypes.objectOf(PropTypes.bool).isRequired,
  confirmations: PropTypes.objectOf(PropTypes.bool).isRequired,
  handleConfirmation: PropTypes.func.isRequired
};

export default EditResumeChecklist;
